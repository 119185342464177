export const MAIN_PAGE = "MAIN_PAGE";
export const CLIENTS_PAGE = "CLIENTS_PAGE";
export const SERVIS_PAGE = "SERVIS_PAGE";
export const PROGRAMMES_PAGE = " PROGRAMMES_PAGE";
export const REPORT_PAGE = "REPORT_PAGE";


// page of service pages
export const REKLAMALAR_PAGE = "REKLAMALAR_PAGE";
export const SERVIS_CHILD_PAGES = "SERVIS_CHILD_PAGES";
export const CLIENTS_CHILD_PAGES = "CLIENTS_CHILD_PAGES";
export const PROGRAMMERS_CHILD_PAGES = "PROGRAMMERS_CHILD_PAGES";
