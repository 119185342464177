// Main templates imports
import ClientTemplate from "./ClientTemplate";
import ProgrammsTemplate from "./ProgrammesTemplate";
import ServiceTemplate from "./ServiceTemplate";
import MainTemplate from "./MainTemplate"

// Basic routes templates
export const AllPages = [
  MainTemplate,
  ClientTemplate,
  ProgrammsTemplate,
  ServiceTemplate,
  // ReportTemplate,
];

// child of service pages templates

export const AllServiceChildPages =[
  // FaolyatTurlaiTemplate,
  // XodimlarTemplate,
  // YunalishlarTemplate,
  // ViloyatlarTemplate,
  // ReklamalarTemplate, 
  // HududTemplate,
  // ShaharTumanTemplate
]
